import { DefaultTheme, createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";
import { lighten } from "polished";
import "@fontsource/roboto";
import "@fontsource/roboto/300.css";
import "@fontsource/playfair-display";

const typography = {
  font: {
    heading: "Playfair Display, Arial",
    base: "Roboto, Arial",
    highlight: "Roboto, Arial",
  },
  size: {
    xs: "0.75rem",
    sm: "0.875rem",
    base: "1rem",
    md: "1.2rem",
    lg: "1.75rem",
    xl: "2rem",
    xxl: "2.75rem",
  },
};

const colors = {
  primary: "#beb199",
  secondary: "#16a085",
  base: "rgba(0, 0, 0, 0.80)",
  baseLight: "rgba(0, 0, 0, 0.60)",
  background: "white",
  accents: {
    sand: "#F2E7DB",
    sandLight: "#FBF6F1",
    sandDark: "#BEB199",
    forest: "#819186",
    pearlBush: "#E3D6C7",
  },
};

const spacing = {
  xs: "0.25rem",
  sm: "0.5rem",
  md: "1rem",
  lg: "2rem",
  xl: "3rem",
};

const layout = {
  gap: "2rem",
  containerWidth: "1280px"
};

export const theme = {
  colors,
  spacing,
  typography,
  layout,
};

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  html, body, #___gatsby, #gatsby-focus-wrapper {
    width: 100%;
    height: 100%;
  }

  body {
    font-family: ${typography.font.base}, sans-serif;
    line-height: 1.6;
    color: ${colors.base};

    h1,h2,h3,h4 {
      font-weight: normal;
      font-family: ${typography.font.heading};
    }

    h2{
      margin-top: 0;
    }

    a {
      color: ${colors.primary};
      text-decoration: none;
      &:hover{
        color: ${lighten(0.1, colors.primary)};
      }
    }

    p {
      margin-bottom: ${spacing.md};
    }
  }
`;
